import S from 'common/service/sanctuary';
import { isEmpty } from 'ramda';
import { connect } from 'react-redux';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bool, arrayOf, func } from 'prop-types';
import Table, { TableHead, TableCell, TableRow, SELECTION_MULTIPLE } from 'common/mdc/table';
import DLMSparser from '../../samples/dlmsDateTimeParser';
import DateTime from 'common/components/dateTime';

import {
  selectors as onDemandReadingSelectors,
} from '@sma/store/device/onDemandReading';

import registerShape from './shape';
import { RegisterObis } from './name';

const messages = defineMessages({
  displaySelected: {
    id: 'smas.registers.on-demand-reading.filter.selected',
    defaultMessage: 'Filter selected',
  },
  obisCode: {
    id: 'smas.registers.on-demand-reading.robisCodegister',
    defaultMessage: 'Obis code/IC',
  },
  name: {
    id: 'smas.registers.on-demand-reading.name',
    defaultMessage: 'Name',
  },
  sampleData: {
    id: 'smas.registers.on-demand-reading.sampleData',
    defaultMessage: 'Sample data',
  },
});

const HYPHEN_SEPERATOR = " - ";
const EMPTY_STRING = "";

const getDescription = (rawValueObj) => {
  if (rawValueObj.description && rawValueObj.description !== null && rawValueObj.description !== undefined) {
    return HYPHEN_SEPERATOR + rawValueObj.description
  }
  return EMPTY_STRING;
}

const unitValue = (value, unit) => `${value} ${unit || ''}`;
const getExp = value => (value.toExponential ? value.toExponential(2) : value);
const getFixed = value => (value.toFixed ? value.toFixed(2) : value);
const expValue = value => (value.toString().indexOf(".") == -1 ? getExp(value) : getFixed(value));

const getNumber = (register, sampleValue, rawValueObj) => {
  const scaledValue = Number(rawValueObj.scaler ? `${rawValueObj.value}e${rawValueObj.scaler}` : rawValueObj.value);
  switch (rawValueObj.type) {
    case 'DLMS_TYPE_DATE':
    case 'DLMS_TYPE_TIME':
    case 'DLMS_TYPE_DATE_TIME':
      return isEmpty(sampleValue) ?
        <DLMSparser
          date={rawValueObj.value.date}
          time={rawValueObj.value.time}
          deviation={rawValueObj.value.deviation}
        /> :
        <DateTime value={sampleValue} title seconds />;
    case 'DLMS_TYPE_DOUBLE_LONG':
    case 'DLMS_TYPE_DOUBLE_U_LONG':
    case 'DLMS_TYPE_INTEGER':
    case 'DLMS_TYPE_LONG':
    case 'DLMS_TYPE_U_LONG':
    case 'DLMS_TYPE_LONG64':
    case 'DLMS_TYPE_U_LONG64':
    case 'DLMS_TYPE_FLOAT32':
    case 'DLMS_TYPE_FLOAT64':
      return `${unitValue(
      Number.isInteger(scaledValue)
        ? scaledValue
        : expValue(scaledValue),
        rawValueObj.unit,
      )} ${getDescription(rawValueObj)}`;
    case 'DLMS_TYPE_OCTET_STRING':
    case 'DLMS_TYPE_VISIBLE_STRING':
      return sampleValue;
    default:
      return unitValue(
      Number.isInteger(scaledValue)
        ? scaledValue
        : expValue(scaledValue),
        register.unit,
      );
  }
}

export const RegisterSelectionTable = ({
  registers,
  sort,
  selected,
  onSelect,
  checked,
  onSelectionChange,
  getSample,
  getRawValue,
  dispatch,
  ...props
}) => (
  <Table selectable selectionType={SELECTION_MULTIPLE} className="onDemandReadingTable" {...props}>
    <TableHead onSelectionChange={onSelectionChange} checked={checked}>
      <TableCell><FormattedMessage {...messages.name} /></TableCell>
      <TableCell><FormattedMessage {...messages.obisCode} /></TableCell>
      <TableCell alignRight><FormattedMessage {...messages.sampleData} /></TableCell>
    </TableHead>
    {registers.map((register, idx) => (
      <TableRow
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        checked={!!selected(register)}
        onSelect={() => onSelect(register)}
        onClick={() => onSelect(register)}
      >
        <TableCell>
          {register.name}
        </TableCell>
        <TableCell>
          <RegisterObis register={register} />
        </TableCell>
        <TableCell>
          {
            S.maybeToNullable(getSample(register)) !== null ?
            getNumber(register, S.maybeToNullable(getSample(register)), S.maybeToNullable(getRawValue(register))) :
            S.maybeToNullable(getSample(register))
          }
        </TableCell>
      </TableRow>
    ))}
  </Table>
);

RegisterSelectionTable.propTypes = {
  registers: arrayOf(registerShape).isRequired,
  sort: func,
  selected: func.isRequired,
  onSelect: func.isRequired,
  onSelectionChange: func.isRequired,
  checked: bool.isRequired,
  getSample: func.isRequired,
  getRawValue: func.isRequired
};

RegisterSelectionTable.defaultProps = {
  sort: () => {},
};

export default connect(
  state => ({
    getSample: onDemandReadingSelectors.getSample(state),
    getRawValue: onDemandReadingSelectors.getRawValue(state),
  }),
)(RegisterSelectionTable);
